import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  var startDate = new Date(2020, 7, 9)
  var today = new Date()
  today.setHours(0, 0, 0)
  var timeDifference = today.getTime() - startDate.getTime()
  var numberOfDays = Math.round(timeDifference / (1000 * 60 * 60 * 24))
  while (numberOfDays >= 164) {
    numberOfDays = numberOfDays - 164
  }
  //   console.log("number of days " + numberOfDays)
  numberOfDays = Math.max(0, Math.min(numberOfDays, 164))
  //   console.log("number of days are now " + numberOfDays)

  var versTitel = "Vers " + numberOfDays
  //   var node = posts[numberOfDays - 1]

  const pageContent = (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      {[posts[numberOfDays]].map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )

  return pageContent
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
